import store from '@/store'
import { createAuthGuard } from '@auth0/auth0-vue'
import { App } from 'vue'
import { createRouter as createVueRouter, createWebHistory, Router } from 'vue-router'

export function createRouter (app: App): Router {
  const router = createVueRouter({
    routes: [
      {
        path: '/',
        beforeEnter: createAuthGuard(app),
        component: () => import('@/views/_layout.vue'),
        children: [
          { path: '', component: () => import('@/views/Index.vue') },
          { path: 'users', component: () => import('@/views/Users.vue') },
          { path: 'data-types', component: () => import('@/views/DataTypes.vue') },
          {
            path: '/org/:id',
            component: () => import('@/views/organization/Index.vue'),
            children: [
              { path: '', component: () => import('@/views/organization/Services.vue') },
              {
                path: 'service/:serviceId',
                component: () => import('@/views/organization/service/Index.vue'),
                children: [
                  { path: '', component: () => import('@/views/organization/service/Service.vue') },
                  {
                    path: 'data-types',
                    component: () => import('@/views/organization/service/DataTypes.vue'),
                  },
                  {
                    path: 'tenants',
                    component: () => import('@/views/organization/service/Tenants.vue'),
                  },
                  {
                    path: 'logs',
                    component: () => import('@/views/organization/service/Logs.vue'),
                  },
                  { path: 'bills', component: () => import('@/views/organization/service/Bills.vue') },
                  { path: 'developers', component: () => import('@/views/organization/service/Developers.vue') },
                ],
              },
            ],
          },
        ],
      },
    ],
    history: createWebHistory(process.env.BASE_URL),
  })

  router.beforeEach(async to => {
    // フロントAPPバージョンのチェック（アップデートがあれば強制リロード）
    if (process.env.NODE_ENV === 'production') await store.dispatch('version', to.path)
  })

  return router
}
