import { createAuth0 } from '@auth0/auth0-vue'
import Quasar from 'quasar/src/vue-plugin.js';
import { createApp } from 'vue'
import App from './App.vue'
import quasarUserOptions from './quasar-user-options'
import { createRouter } from './router'
import store from './store'
// import './registerServiceWorker'

const app = createApp(App)

const auth0 = createAuth0({
  domain: process.env.VUE_APP_SMART_PHR_DOMAIN,
  clientId: process.env.VUE_APP_SMART_PHR_CLIENT_ID,
  authorizationParams: {
    redirect_uri: window.location.origin,
    audience: process.env.VUE_APP_SMART_PHR_AUDIENCE,
  },
})
export const router = createRouter(app)

app.use(Quasar, quasarUserOptions).use(store).use(router).use(auth0).mount('#app')
