import api from '@/api'
import { User, UserInput } from '@/models/user'
import { AxiosError } from 'axios'

export const verifyAdminUser = async (): Promise<any> => {
  return await api
    .get('v1/admin/verify-user')
    .then((resp: any) => resp.data)
    .catch((e: AxiosError) => {
      throw e
    })
}

export const fetchAdminUsers = async (): Promise<any> => {
  return await api
    .get('https://smartphr.jp.auth0.com/api/v2/roles/admin/users')
    .then((resp: any) => resp.data)
    .catch((e: AxiosError) => {
      throw e
    })
}

export const createAdminUser = async (params: UserInput): Promise<User | null> => {
  const user = await api
    .post('v1/admin/users', params)
    .then((resp: any) => User.createFromJson(resp.data))
    .catch((e: AxiosError) => {
      throw e
    })

  return user
}

export const updateAdminUser = async (id: string, params: UserInput): Promise<User | null> => {
  const user = await api
    .put(`v1/admin/users/${id}`, params)
    .then((resp: any) => User.createFromJson(resp.data))
    .catch((e: AxiosError) => {
      throw e
    })

  return user
}

export const deleteAdminUser = async (id: string): Promise<boolean> => {
  const result = await api
    .delete(`v1/admin/users/${id}`)
    .then(_ => true)
    .catch(() => false)

  return result
}
