import axios, { InternalAxiosRequestConfig } from 'axios'
import store from './store'

const api = axios.create({
  baseURL: process.env.VUE_APP_API_ENDPOINT,
})

api.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  config['headers'] = config.headers ?? {}
  if (store.state.token) {
    config.headers['Authorization'] = `Bearer ${store.state.token}`
  }
  return config
})

api.interceptors.response.use(
  response => response,
  error => {
    if (error.response.status === 401) {
      store.commit('token', null)
    }
    return Promise.reject(error)
  },
)

export default api
